<template>
  <nav>
    <ul class="enlacesHeader d-flex justify-space-around pl-0">
      <li class="boxEnlaces" v-for="enlace in menuComponentes" :key="enlace.label">
        <!-- <router-link :to="`${enlace.to}`" class="menuEnlaces" >{{enlace.label}}</router-link> -->
        <a :href="`${enlace.to}`" class="menuEnlaces">{{ enlace.label }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "HeaderMenu",
  data() {
    return {
      // right: true,
      // rightDrawer: false,
      menuComponentes: [
        { label: "Home.", to: "/home" },
        { label: "About.", to: "/home#about" },
        { label: "Skills.", to: "/home#skills" },
        { label: "Portfolio.", to: "/home#works" },
        { label: "Contact.", to: "/home#contact" },
      ],
    };
  },
};
</script>

<style>

.boxEnlaces{
  list-style-type: none;
  min-width: 85px;
  min-height: 30px;
}

.enlacesHeader{
  gap:35px;
}
</style>