import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        redirect: "/home"
    },
    {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "Home" */ '../views/HomeView.vue')
    },
    {
      path: '/proyects/:id?',
      name: 'proyects',
    // route level code-splitting
    // this generates a separate chunk (proyects.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "proyects" */ '../views/ProyectsView.vue')
    },
    {
      path: "*",
      name: "notfound",
      component: () =>
        import(/* webpackChunkName: "errors" */ "@/views/ErrorView.vue")
    }
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
