<template>
  <v-app>
    <v-app-bar app class="headerBox" flat hide-on-scroll elevate-on-scroll>
      <v-container class="py-0 fill-height">
        <v-app-bar-title class="headerTitle orange--text"
          >Oscar Jimenez Moya</v-app-bar-title
        >
        <v-spacer></v-spacer>
        <HeaderMenu class="d-none d-md-flex" />
        <v-spacer></v-spacer>
        <v-switch
          class="mr-10 d-none d-md-flex"
          v-model="darkTheme"
          @click="darkThemeClicked"
        ></v-switch>
        <v-app-bar-nav-icon
          class="d-md-none"
          @click="rightDrawer = true"
        ></v-app-bar-nav-icon>
      </v-container>
    </v-app-bar>
    <v-main>
      <v-navigation-drawer
        class="d-md-none"
        v-model="rightDrawer"
        :right="right"
        fixed
      >
        <v-list>
          <v-list-item
            class="menuEnlaces"
            v-for="enlace in menuComponentes"
            :key="enlace.label"
            :href="enlace.to"
            link
          >
            <v-list-item-action>
              <v-icon light> {{ enlace.icono }}</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ enlace.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <router-view />
    </v-main>
    <FooterComponent />
  </v-app>
</template>

<script>
import HeaderMenu from "./components/Header/HeaderMenu.vue";
import FooterComponent from "./components/Footer/FooterComponent.vue";

export default {
  name: "App",
  components: {
    HeaderMenu,
    FooterComponent,
  },
  data: () => ({
    select: { state: "Español", abbr: "ES" },
    languages: [
      { state: "English", abbr: "EN" },
      { state: "Arabe", abbr: "AR" },
    ],
    menuComponentes: [
      { label: "Home.", to: "/home", icono: "mdi-home" },
      { label: "About.", to: "/home#about", icono: "mdi-information" },
      {
        label: "Skills.",
        to: "/home#skills",
        icono: "mdi-book-open-page-variant",
      },
      { label: "Portfolio.", to: "/home#works", icono: "mdi-briefcase" },
      { label: "Contact.", to: "/home#contact", icono: "mdi-account-box" },
    ],
    darkTheme: true,
    right: null,
    rightDrawer: false,
  }),
  methods: {
    darkThemeClicked() {
      this.$vuetify.theme.dark = this.darkTheme;
    },
  },
};
</script>



<style>
@import url("https://fonts.googleapis.com/css2?family=Saira+Stencil+One&display=swap");

html {
  scroll-behavior: smooth !important;
}

ul li {
  list-style-type: none;
}

.v-app-bar-title__content {
  width: auto !important;
}

.headerBox {
  transition: all 0.6s ease-in-out;
  background-color: transparent !important;
}

.headerBox.v-app-bar--is-scrolled {
  background-color: rgb(48, 48, 48) !important;
}

.headerTitle {
  width: auto;
  font-family: "Saira Stencil One";
}

.optionBox {
  align-items: center;
}

.menuEnlaces {
  width: 100%;
  position: relative;
  color: rgb(230, 204, 204) !important ;
  text-decoration: none;
  transition: all 0.5s ease;
  text-align: center;
  list-style-type: none;
}

.menuEnlaces:hover {
  color: rgb(227, 132, 69) !important;
  font-size: 20px;
  font-weight: 700;
}

a {
  text-decoration: none;
}
</style>
