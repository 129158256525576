<template>
  <v-footer class="mt-8" dark padless>
    <v-row justify="center" no-gutters>
      <v-col class="py-4 text-center white--text" cols="12">
        {{ new Date().getFullYear() }} — <strong>Oscarjim1130@gmail.com</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>
